<script lang="ts" setup>
const layout = useDefaultLayout();

const openMenu = ref(false);
const auth = useAuth();

if (auth.get()) {
  await useGo7seasApi(apiUrl("/catalog/customers/login"));
}

function toggleMenu() {
  openMenu.value = !openMenu.value;
}
</script>

<template>
  <header
    class="fixed z-header flex h-header w-full items-center bg-g7-blue text-white lg:static"
  >
    <div class="flex grow items-center justify-between">
      <NuxtLink to="/" external class="block">
        <CloudinaryImg
          public-id="go7seas-logo"
          :steps="[200, 400, 600]"
          class="block w-40 px-2 sm:w-48 md:pl-6 xl:w-60 xl:pl-12"
        />
      </NuxtLink>
      <HeaderHotline class="flex h-full items-center px-2 lg:pr-12" />
    </div>
    <NuxtLink class="h-full" to="/mygo7seas">
      <div
        class="hidden h-full cursor-pointer items-center px-11 uppercase lg:flex"
        :class="{
          'bg-golden-bell hover:bg-orange-600 active:bg-orange-700':
            auth.check.value,
          'bg-teal hover:bg-[#3c94be] active:bg-[#3584a9]': auth.guest.value,
        }"
      >
        <div class="relative mr-3.5 size-11">
          <IconsUser class="absolute size-11 fill-white p-3" />
          <IconsCircle class="absolute size-11 fill-white" />
        </div>
        <span>{{ layout.header.action_button.label }}</span>
      </div>
    </NuxtLink>

    <div
      class="mx-2 flex size-10 cursor-pointer items-center justify-center lg:hidden"
    >
      <IconsBars class="size-8 fill-white" @click="toggleMenu" />
    </div>
    <div
      class="fixed top-[5.625rem] z-40 flex w-screen overflow-hidden transition duration-100 lg:hidden"
      @click="toggleMenu"
    >
      <MobileMenu v-if="openMenu" :items="layout.main_menu.items" />
    </div>
    <div
      class="lg:hidden"
      :class="{
        'fixed inset-0 z-30 h-full w-screen bg-slate-500 opacity-50 transition duration-100':
          openMenu,
        hidden: !openMenu,
      }"
      @click="toggleMenu"
    />
  </header>
</template>
