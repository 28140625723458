<template>
  <div
    class="flex justify-center text-g7-blue"
    :class="{
      'absolute z-50 w-full bg-gradient-to-b from-g7-blue py-10 !text-white':
        floating,
    }"
  >
    <template v-for="(item, index) in items" :key="index">
      <NuxtLink
        v-if="!isLoading"
        class="mx-6 my-1 flex flex-col items-center whitespace-nowrap uppercase lg:text-xl xl:text-3xl"
        :to="item.context"
      >
        <span v-html="item.label" />
        <div
          class="h-0.5 w-0 transition-[width] delay-150 ease-linear"
          :class="classes(item)"
        />
      </NuxtLink>
      <div
        v-else
        class="mx-6 my-1 flex flex-col items-center whitespace-nowrap uppercase lg:text-xl xl:text-3xl"
      >
        <span v-html="item.label" />
        <div
          class="h-0.5 w-0 transition-[width] delay-150 ease-linear"
          :class="classes(item)"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { MenuItem } from "@/types/layout";

const layout = useDefaultLayout();
const items = computed(() => layout.value.main_menu.items);
const floating = useDiaShow().has();
const isLoading = useApiLoading();

function classes(item: MenuItem) {
  return {
    "bg-white w-[100%]": floating.value ? item.active : false,
    "bg-g7-blue w-[100%]": !floating.value ? item.active : false,
    "bg-white": floating.value,
    "bg-g7-blue": !floating.value,
  };
}
</script>
